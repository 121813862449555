import React from 'react'
import PageTransition from 'gatsby-plugin-page-transitions'

import Layout from "../global/layout"
import SEO from "../global/seo"

import CircleButton from '../components/circle-button'

import CSS from './legal.module.css'

const LegalPage = () => {
    return (
        <PageTransition>
            <Layout>
            
                <SEO title="Legal" />
        
                <div className={CSS.container}>

                    <h1 className={CSS.logotype}>onedotsix</h1>

                    <div className={CSS.homeButton}>
                        <CircleButton to="/" >home</CircleButton>
                    </div>

                    <article className={CSS.bodyText}>
                        

                        <h1>Legal</h1>

                        <div style={{height: "20vh"}}></div>
                        <h2>Privacy</h2>
                        <p>We value yours and our own privacy very highly.</p>
                        <p>Whilst we monitor activity on our website, we do so in a highly ethical fashion. Our analytics system provides everything we need to know and nothing we don't. We don't collect any personal or invasive data about our website users.</p>

                        <br/>
                        <h2>Cookie Policy</h2>
                        <p>Simply put, we don't use them.</p>


                    </article>
                    

                </div>

            
            </Layout>
        </PageTransition>
    )
}

export default LegalPage